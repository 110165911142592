import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonLink from "../../components/buttonLink"
import RadioQuestion from "../../components/radioQuestion"
import conference from "../../images/topic-1/step-4/international-conference.jpg"
import billdeer from "../../images/topic-1/step-4/bill.jpg"
import desirent from "../../images/topic-1/step-4/desi.jpg"
import anusha from "../../images/topic-1/step-4/anusha.jpg"
import jeanshaw from "../../images/topic-1/step-4/jean.jpg"
import billEq1 from "../../images/topic-1/step-4/equation-1.png"
import billEq2 from "../../images/topic-1/step-4/equation-2.png"
import billEq3 from "../../images/topic-1/step-4/equation-3.png"
import billEq4 from "../../images/topic-1/step-4/equation-4.png"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import RespMsg from "../../components/respMsg"
import Form from "react-bootstrap/Form"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const ExpGrowthStep4 = ({ location }) => {
  const { t } = useTranslation()

  const renderTooltipHelp = props => (
    <Tooltip id="button-tooltip" className="myTooltip" {...props}>
      <ul style={{ listStyleType: "none", textAlign: "left" }}>
        {" "}
        {parse(t("Topic1_Step4_tooltip"))}{" "}
      </ul>
    </Tooltip>
  )
  const experts = [
    //0
    {
      name: parse(t("Topic1_Step4_experts[0].name")),
      image: conference,
      alt: parse(t("Topic1_Step4_experts[0].alt")),
      text: parse(t("Topic1_Step4_experts[0].text")),
      text2: "",
      hint: "",
      question: "",
      answer: 0,
      placeHolder: "",
      errorMsg: "",
      finalErrorMsg: "",
      radio2: "",
      radio2Questions: [""],
    },
    //1==============================
    {
      name: parse(t("Topic1_Step4_experts[1].name")),
      image: billdeer,
      alt: parse(t("Topic1_Step4_experts[1].alt")),
      text: parse(t("Topic1_Step4_experts[1].text")),
      text2: parse(t("Topic1_Step4_experts[1].text2")),
      hint: parse(t("Topic1_Step4_experts[1].hint")),
      question: parse(t("Topic1_Step4_experts[1].question")),
      answer: 3,
      placeHolder: "rmax",
      errorMsg: parse(t("Topic1_Step4_experts[1].errorMsg")),
      correctMsg: parse(t("Topic1_Step4_experts[1].correctMsg")),
      finalErrorMsg: parse(t("Topic1_Step4_experts[1].finalErrorMsg")),
      radio2: parse(t("Topic1_Step4_experts[1].radio2")),
      radio2Questions: [
        //0
        {
          label: parse(t("Topic1_Step4_experts[1].radio2Questions[0].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[1].radio2Questions[0].msg")),
        },
        //1
        {
          label: parse(t("Topic1_Step4_experts[1].radio2Questions[1].label")),
          answer: true,
          msg: parse(t("Topic1_Step4_experts[1].radio2Questions[1].msg")),
        },
        //2
        {
          label: parse(t("Topic1_Step4_experts[1].radio2Questions[2].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[1].radio2Questions[2].msg")),
        },
      ],
    },
    //2==============================
    {
      name: parse(t("Topic1_Step4_experts[2].name")),
      image: desirent,
      alt: parse(t("Topic1_Step4_experts[2].alt")),
      text: parse(t("Topic1_Step4_experts[2].text")),
      text2: parse(t("Topic1_Step4_experts[2].text2")),
      hint: parse(t("Topic1_Step4_experts[2].hint")),
      question: parse(t("Topic1_Step4_experts[2].question")),
      answer: 1045000,
      placeHolder: "N",
      errorMsg: parse(t("Topic1_Step4_experts[2].errorMsg")),
      correctMsg: parse(t("Topic1_Step4_experts[2].correctMsg")),
      finalErrorMsg: parse(t("Topic1_Step4_experts[2].finalErrorMsg")),
      radio2: parse(t("Topic1_Step4_experts[2].radio2")),
      radio2Questions: [
        //0
        {
          label: parse(t("Topic1_Step4_experts[2].radio2Questions[0].label")),
          answer: true,
          msg: parse(t("Topic1_Step4_experts[2].radio2Questions[2].msg")),
        },
        //1
        {
          label: parse(t("Topic1_Step4_experts[2].radio2Questions[1].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[2].radio2Questions[0].msg")),
        },
        //2
        {
          label: parse(t("Topic1_Step4_experts[2].radio2Questions[2].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[2].radio2Questions[1].msg")),
        },
      ],
    },
    //3==============================
    {
      name: parse(t("Topic1_Step4_experts[3].name")),
      image: anusha,
      alt: parse(t("Topic1_Step4_experts[3].alt")),
      text: parse(t("Topic1_Step4_experts[3].text")),
      text2: parse(t("Topic1_Step4_experts[3].text2")),
      hint: parse(t("Topic1_Step4_experts[3].hint")),
      question: parse(t("Topic1_Step4_experts[3].question")),
      answer: 2,
      placeHolder: "T",
      errorMsg: parse(t("Topic1_Step4_experts[3].errorMsg")),
      correctMsg: parse(t("Topic1_Step4_experts[3].correctMsg")),
      finalErrorMsg: parse(t("Topic1_Step4_experts[3].finalErrorMsg")),
      radio2: parse(t("Topic1_Step4_experts[3].radio2")),
      radio2Questions: [
        //0
        {
          label: parse(t("Topic1_Step4_experts[3].radio2Questions[0].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[3].radio2Questions[0].msg")),
        },
        //1
        {
          label: parse(t("Topic1_Step4_experts[3].radio2Questions[1].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[3].radio2Questions[1].msg")),
        },
        //2
        {
          label: parse(t("Topic1_Step4_experts[3].radio2Questions[2].label")),
          answer: true,
          msg: parse(t("Topic1_Step4_experts[3].radio2Questions[2].msg")),
        },
      ],
    },
    //4==============================
    {
      name: parse(t("Topic1_Step4_experts[4].name")),
      image: jeanshaw,
      alt: parse(t("Topic1_Step4_experts[4].alt")),
      text: parse(t("Topic1_Step4_experts[4].text")),
      text2: parse(t("Topic1_Step4_experts[4].text2")),
      hint: parse(t("Topic1_Step4_experts[4].hint")),
      question: parse(t("Topic1_Step4_experts[4].question")),
      answer: 1078772, //NOT USED
      placeHolder: "N", //NOT USED
      errorMsg: parse(t("Topic1_Step4_experts[4].errorMsg")),
      correctMsg: parse(t("Topic1_Step4_experts[4].correctMsg")),
      finalErrorMsg: parse(t("Topic1_Step4_experts[4].finalErrorMsg")),
      radio2: parse(t("Topic1_Step4_experts[4].radio2")),
      radio2Questions: [
        //0
        {
          label: parse(t("Topic1_Step4_experts[4].radio2Questions[0].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[4].radio2Questions[0].msg")),
        },
        //1
        {
          label: parse(t("Topic1_Step4_experts[4].radio2Questions[1].label")),
          answer: false,
          msg: parse(t("Topic1_Step4_experts[4].radio2Questions[1].msg")),
        },
        //2
        {
          label: parse(t("Topic1_Step4_experts[4].radio2Questions[2].label")),
          answer: true,
          msg: parse(t("Topic1_Step4_experts[4].radio2Questions[2].msg")),
        },
      ],
    },
  ]

  const [currentExpert, setCurrentExpert] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)

  const currentPages = [
    //0 main page==============================
    {
      page: parse(t("Topic1_Step4_currentPages[0].page")),
      instructions: parse(t("Topic1_Step4_currentPages[0].instructions")),
    },
    //1 bill==============================
    {
      page: parse(t("Topic1_Step4_currentPages[1].page")),
      instructions: parse(t("Topic1_Step4_currentPages[1].instructions")),
    },

    //2
    {
      page: parse(t("Topic1_Step4_currentPages[2].page")),
      instructions: parse(t("Topic1_Step4_currentPages[2].instructions")),
    },

    //3
    {
      page: parse(t("Topic1_Step4_currentPages[3].page")),
      instructions: parse(t("Topic1_Step4_currentPages[3].instructions")),
    },

    //4
    {
      page: parse(t("Topic1_Step4_currentPages[4].page")),
      instructions: parse(t("Topic1_Step4_currentPages[4].instructions")),
    },

    //5 desi==============================
    {
      page: parse(t("Topic1_Step4_currentPages[5].page")),
      instructions: parse(t("Topic1_Step4_currentPages[5].instructions")),
    },

    //6
    {
      page: parse(t("Topic1_Step4_currentPages[6].page")),
      instructions: parse(t("Topic1_Step4_currentPages[6].instructions")),
    },

    //7
    {
      page: parse(t("Topic1_Step4_currentPages[7].page")),
      instructions: parse(t("Topic1_Step4_currentPages[7].instructions")),
    },

    //8
    {
      page: parse(t("Topic1_Step4_currentPages[8].page")),
      instructions: parse(t("Topic1_Step4_currentPages[8].instructions")),
    },

    //9 Dr. Anusha Gandhari==============================
    {
      page: parse(t("Topic1_Step4_currentPages[9].page")),
      instructions: parse(t("Topic1_Step4_currentPages[9].instructions")),
    },

    //10
    {
      page: parse(t("Topic1_Step4_currentPages[10].page")),
      instructions: parse(t("Topic1_Step4_currentPages[10].instructions")),
    },

    //11
    {
      page: parse(t("Topic1_Step4_currentPages[11].page")),
      instructions: parse(t("Topic1_Step4_currentPages[11].instructions")),
    },

    //12 Jean Shaw==============================
    {
      page: parse(t("Topic1_Step4_currentPages[12].page")),
      instructions: parse(t("Topic1_Step4_currentPages[12].instructions")),
    },

    //13
    {
      page: parse(t("Topic1_Step4_currentPages[13].page")),
      instructions: parse(t("Topic1_Step4_currentPages[13].instructions")),
    },
  ]

  const radioQuestions = [
    //0
    {
      label: (
        <img
          src={billEq1}
          alt={parse(t("Topic1_Step4_radioQuestions[0].alt"))}
          height="30px"
        />
      ),
      text: parse(t("Topic1_Step4_radioQuestions[0].text")),
      answer: false,
      msg: parse(t("Topic1_Step4_radioQuestions[0].msg")),
    },
    //1
    {
      label: (
        <img
          src={billEq2}
          alt={parse(t("Topic1_Step4_radioQuestions[1].alt"))}
          height="17px"
        />
      ),
      text: parse(t("Topic1_Step4_radioQuestions[1].text")),
      answer: true,
      msg: parse(t("Topic1_Step4_radioQuestions[1].msg")),
    },
    //2
    {
      label: (
        <img
          src={billEq3}
          alt={parse(t("Topic1_Step4_radioQuestions[2].alt"))}
          height="17px"
        />
      ),
      text: parse(t("Topic1_Step4_radioQuestions[2].text")),
      answer: true,
      msg: parse(t("Topic1_Step4_radioQuestions[2].msg")),
    },
    //3
    {
      label: (
        <img
          src={billEq4}
          alt={parse(t("Topic1_Step4_radioQuestions[3].alt"))}
          height="17px"
        />
      ),
      text: parse(t("Topic1_Step4_radioQuestions[3].text")),
      answer: false,
      msg: parse(t("Topic1_Step4_radioQuestions[3].msg")),
    },
  ]

  const yearNumbers = [
    [0, 0, 1, 0.33],
    [9, 8, 3, 6.7],
    [183, 126, 90, 133],
    ["3,380", "2,765", "1,869", "2,671"],
    ["6,8274", "58,329", "34,524", "53,709"],
  ]

  const [disableChoices, setDisableChoices] = useState(false)
  const [selectedEq, setSelectedEq] = useState()
  const [rMaxAnswer, setRMaxAnswer] = useState()
  const [errorCount, setErrorCount] = useState(0)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true)
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const mainTitle = currentPages[0].page
  const [showRadioCheck, setShowRadioCheck] = useState(true)
  const [showRadio2Check, setShowRadio2Check] = useState(true)
  const [resetPlaceholder, setResetPlaceholder] = useState(false)

  // =============================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic1_Step4_pageTitle"))} />
      <MyBreadcrumb
        topicLink="exponentialgrowth"
        topicTitle={parse(t("Topic1_Step4_topicTitle"))}
        currentTitle={currentPages[currentPage].page}
      />
      {/* =============================================================
      4 INTRO SECTION
      ============================================================= */}
      <div id="top">
        {/* <div>
          expert: {currentExpert} currentpage: {currentPage}
        </div> */}
        <div className="homeContent" hidden={currentPage != 0}>
          <Row>
            <Col sm="12">
              {" "}
              <h3 className="blueTitleOFF">{mainTitle}</h3>
              {experts[0].text}
              <Instructions content={currentPages[currentPage].instructions} />
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ marginBottom: `1.45rem` }}>
                <center>
                  <img
                    src={experts[0].image}
                    alt={experts[0].alt}
                    style={{ borderRadius: "5px" }}
                  />
                </center>
              </div>
            </Col>
          </Row>
        </div>
        {/* =============================================================
      SHARED TOP
      ============================================================= */}
        <div className="homeContent" hidden={currentPage == 0}>
          <Row>
            <Col sm="12">
              {" "}
              <h3 className="blueTitleOFF">{mainTitle}</h3>
              <Row>
                <Col md="6">
                  {" "}
                  <img
                    src={experts[currentExpert].image}
                    alt={experts[currentExpert].alt}
                    className="round"
                  />
                </Col>
                <Col
                  md="6"
                  hidden={
                    (currentPage == 4) |
                    (currentPage == 8) |
                    (currentPage == 11) |
                    (currentPage == 13)
                  }
                >
                  <h4>
                    {experts[currentExpert].name}{" "}
                    {parse(t("Topic1_Step4_asks"))}:
                  </h4>
                  {experts[currentExpert].text}
                </Col>
                <Col
                  md="6"
                  hidden={
                    currentPage != 4 &&
                    currentPage != 8 &&
                    currentPage != 11 &&
                    currentPage != 13
                  }
                >
                  <h4>
                    {experts[currentExpert].name}{" "}
                    {parse(t("Topic1_Step4_says"))}:
                  </h4>
                  {experts[currentExpert].text2}
                </Col>
              </Row>
              <Instructions content={currentPages[currentPage].instructions} />
            </Col>
          </Row>
        </div>
        {/* =============================================================
      4 FIRST RADIO SECTION
      ============================================================= */}
        <div hidden={currentPage != 1 && currentPage != 5 && currentPage != 9}>
          <Form>
            <RadioQuestion
              data={[
                {
                  id: "radio1",
                  name: "formRadios",
                  label: radioQuestions[0].label, //radioQuestions.bill[currentPage].label,
                  text: radioQuestions[0].text,
                  answer: radioQuestions[0].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio2",
                  name: "formRadios",
                  label: radioQuestions[1].label, //radioQuestions.bill[currentPage].label,
                  text: radioQuestions[1].text,
                  answer: radioQuestions[1].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio3",
                  name: "formRadios",
                  label: radioQuestions[2].label, //radioQuestions.bill[currentPage].label,
                  text: radioQuestions[2].text,
                  answer: radioQuestions[2].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio4",
                  name: "formRadios",
                  label: radioQuestions[3].label, //radioQuestions.bill[currentPage].label,
                  text: radioQuestions[3].text,
                  answer: radioQuestions[3].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
              ]}
              // disabled={disableChoices == true}
              myOnClick={(answer, label, id) => {
                switch (id) {
                  case "radio1":
                    setMsgContent(radioQuestions[0].msg)
                    break
                  case "radio2":
                    setMsgContent(radioQuestions[1].msg)
                    break
                  case "radio3":
                    setMsgContent(radioQuestions[2].msg)
                    break
                  case "radio4":
                    setMsgContent(radioQuestions[3].msg)
                    break
                  default:
                    return null
                }
                if (answer == true) {
                  setMsgType("correct")
                } else {
                  setMsgType("error")
                }
                setSelectedEq(label)
                setShowMsg(true)
                setDisableChoices(answer)
                setNextBtnEnabled(true)
              }}
              disabled={disableChoices}
            />
          </Form>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>
        {/* =============================================================
      4 QUESTION SECTION
      ============================================================= */}
        <div hidden={currentPage != 2 && currentPage != 6 && currentPage != 10}>
          {" "}
          <Row id="bottom">
            <Col md="6">
              <p className="bold">{parse(t("Topic1_Step4_question[0]"))}</p>{" "}
              {selectedEq}
              <p>
                <span className="bold">
                  {parse(t("Topic1_Step4_question[1]"))}
                </span>{" "}
                {experts[currentExpert].hint}{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipHelp}
                >
                  <span className="popup">
                    {parse(t("Topic1_Step4_question[2]"))}
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                </OverlayTrigger>{" "}
              </p>
              <p>{experts[currentExpert].question}</p>
              <Row>
                <Col xs="4">
                  <Form.Control
                    placeholder={experts[currentExpert].placeHolder}
                    disabled={(errorCount >= 4) | nextBtnEnabled}
                    onChange={e => setRMaxAnswer(e.target.value)}
                    value={
                      resetPlaceholder == true
                        ? experts[currentExpert].placeHolder
                        : null
                    }
                    type="number"
                    // minlength="9"
                    // maxlength="11"
                  />
                </Col>
                <br />
                <br />
                <Col xs="8">
                  <ButtonLink
                    // btnLink="#top"
                    btnWidth
                    variant="secondary"
                    className="btnNext"
                    btnText={parse(t("Submit"))}
                    disabled={(errorCount >= 4) | nextBtnEnabled}
                    onClick={() => {
                      if (
                        currentExpert == 2 &&
                        rMaxAnswer >= 1045000 &&
                        rMaxAnswer <= 1101000
                      ) {
                        setMsgContent(experts[currentExpert].correctMsg)
                        setMsgType("correct")
                        setNextBtnEnabled(true)
                      } else if (rMaxAnswer != experts[currentExpert].answer) {
                        setMsgContent(experts[currentExpert].errorMsg)
                        setMsgType("error")
                      } else {
                        setMsgContent(experts[currentExpert].correctMsg)
                        setMsgType("correct")
                        setNextBtnEnabled(true)
                      }
                      if (
                        errorCount >= 3 &&
                        rMaxAnswer != experts[currentExpert].answer
                      ) {
                        setMsgContent(experts[currentExpert].finalErrorMsg)
                        setMsgType("error")
                        setNextBtnEnabled(true)
                      }
                      setShowMsg(true)
                      setErrorCount(errorCount + 1)
                    }}
                    // btnColor="#4A331C"
                  />
                </Col>
                <br />
                <br />
              </Row>
            </Col>

            <Col sm="6" md="6">
              <h4 className="">{parse(t("Topic1_Step4_table[0]"))}</h4>
              <table>
                <tr>
                  <th>{parse(t("Topic1_Step4_table[1]"))}</th>
                  <th>{parse(t("Topic1_Step4_table[2]"))}</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>{yearNumbers[0][3]}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>{yearNumbers[1][3]}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>{yearNumbers[2][3]}</td>
                </tr>{" "}
                <tr>
                  <td>4</td>
                  <td>{yearNumbers[3][3]}</td>
                </tr>{" "}
                <tr>
                  <td>5</td>
                  <td>{yearNumbers[4][3]}</td>
                </tr>
              </table>
            </Col>
            <Col md="12"></Col>
          </Row>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>

        {/* =============================================================
      4 SECOND RADIO SECTION
      ============================================================= */}
        <div hidden={currentPage != 3 && currentPage != 7 && currentPage != 12}>
          <p className="bold">{experts[currentExpert].radio2}</p>
          <Form>
            <RadioQuestion
              data={[
                {
                  id: "radio5",
                  name: "formRadios2",
                  label: experts[currentExpert].radio2Questions[0].label, //radioQuestions.bill[currentPage].label,
                  answer: experts[currentExpert].radio2Questions[0].answer,
                  checked: showRadio2Check == false ? 0 : null,
                },
                {
                  id: "radio6",
                  name: "formRadios2",
                  label: experts[currentExpert].radio2Questions[1].label, //radioQuestions.bill[currentPage].label,
                  answer: experts[currentExpert].radio2Questions[1].answer,
                  checked: showRadio2Check == false ? 0 : null,
                },
                {
                  id: "radio7",
                  name: "formRadios2",
                  label: experts[currentExpert].radio2Questions[2].label, //radioQuestions.bill[currentPage].label,
                  answer: experts[currentExpert].radio2Questions[2].answer,
                  checked: showRadio2Check == false ? 0 : null,
                },
              ]}
              // disabled={disableChoices == true}
              myOnClick={(answer, label, id) => {
                switch (id) {
                  case "radio5":
                    setMsgContent(experts[currentExpert].radio2Questions[0].msg)
                    break
                  case "radio6":
                    setMsgContent(experts[currentExpert].radio2Questions[1].msg)
                    break
                  case "radio7":
                    setMsgContent(experts[currentExpert].radio2Questions[2].msg)
                    break
                  default:
                    return null
                }
                if (answer == true) {
                  setMsgType("correct")
                } else {
                  setMsgType("error")
                }
                setShowMsg(true)
                setDisableChoices(answer)
                setNextBtnEnabled(answer)
              }}
              disabled={disableChoices}
            />
          </Form>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>

        {/* =============================================================
      4 BOTTOM NAV SECTION
      ============================================================= */}
        <div>
          <hr />
          <Row>
            <Col sm="12" md="12" className="alignRight">
              <div>
                {/* PREV BTN */}
                <ButtonLink
                  btnLink={
                    currentPage == 0 ? "/exponentialgrowth/step3" : "#myHeader"
                  }
                  variant="info"
                  btnText={parse(t("Previous"))}
                  className="btnPrev"
                  onClick={() => {
                    if (currentPage != 0) {
                      setErrorCount(0)
                      setShowMsg(false)
                      setNextBtnEnabled(false)
                      setCurrentPage(currentPage - 1)
                      switch (currentPage) {
                        // first radio=========================
                        case 1:
                          setNextBtnEnabled(true)
                          setShowRadioCheck(false)
                          break
                        case 5:
                        case 9:
                          setNextBtnEnabled(true)
                          setShowRadioCheck(false)
                          setCurrentExpert(currentExpert - 1)
                          break
                        // question=========================
                        case 2:
                        case 6:
                        case 10:
                          setShowRadioCheck(true)
                          setResetPlaceholder(true)
                          break
                        // second radio=========================
                        case 3:
                        case 7:
                          setShowRadio2Check(false)
                          setResetPlaceholder(false)
                          break
                        // expert response=========================
                        case 4:
                        case 8:
                        case 11:
                        case 13:
                          setShowRadio2Check(true)
                          break
                        // JEAN====
                        case 12:
                          setNextBtnEnabled(true)
                          setResetPlaceholder(false)
                          setShowRadio2Check(false)
                          setCurrentExpert(currentExpert - 1)
                        default:
                          return null
                      }
                    }
                  }}
                ></ButtonLink>
                {/* NEXT BTN */}
                <ButtonLink
                  btnLink="#myHeader"
                  btnText={parse(t("Next"))}
                  variant="info"
                  className="btnNext"
                  hidden={currentPage == 13}
                  disabled={
                    nextBtnEnabled == false &&
                    currentPage !== currentPages[0].page &&
                    currentPage != 4 &&
                    currentPage != 8 &&
                    currentPage != 11
                  }
                  onClick={() => {
                    setCurrentPage(currentPage + 1)
                    setDisableChoices(false)
                    setShowMsg(false)
                    setNextBtnEnabled(false)
                    setErrorCount(0)
                    switch (currentPage) {
                      // first radio=========================
                      case 1:
                      case 5:
                      case 9:
                        setNextBtnEnabled(false)
                        setShowRadioCheck(false)
                        setResetPlaceholder(false)
                        break
                      // question=========================
                      case 2:
                      case 6:
                      case 10:
                        setShowRadioCheck(true)
                        setShowRadio2Check(true)
                        setResetPlaceholder(true)
                        break
                      // second radio=========================
                      case 3:
                      case 7:
                        setShowRadio2Check(false)
                        break
                      // expert response=========================
                      case 4:
                      case 8:
                      case 11:
                        setShowRadioCheck(true) //reset radio1
                        setShowRadio2Check(true) //reset radio1
                        setResetPlaceholder(false)
                        setCurrentExpert(currentExpert + 1)
                        break
                      //JEAN======
                      case 12:
                        setNextBtnEnabled(false)
                        setShowRadio2Check(false)
                        setResetPlaceholder(false)
                      default:
                        return null
                    }
                  }}

                  // {() => {
                  //   if (currentPage == 0) {

                  //   }
                  //   //next expert
                  //   if (
                  //     (currentPage == 4) |
                  //     (currentPage == 8) |
                  //     (currentPage == 10)
                  //   ) {
                  //     setShowRadioCheck(true) //reset radio1
                  //     setShowRadio2Check(false) //reset radio1
                  //     setCurrentExpert(currentExpert + 1)
                  //   }
                  //   if (
                  //     (currentPage == 1) |
                  //     (currentPage == 2) |
                  //     (currentPage == 5) |
                  //     (currentPage == 9) |
                  //     (currentPage == 11)
                  //   ) {
                  //     setResetPlaceholder(false)
                  //     setShowRadio2Check(true) //reset radio2
                  //   }
                  //   setCurrentPage(currentPage + 1)
                  //   // setNextCount(nextCount + 1)
                  //   setDisableChoices(false)
                  //   setShowMsg(false)
                  //   setNextBtnEnabled(false)
                  //   setErrorCount(0)
                  //   setShowRadioCheck(false)
                  //   setShowRadio2Check(false)
                  // }}
                />
                {/* FINAL NEXT BTN */}
                <ButtonLink
                  btnLink="/logisticgrowth"
                  variant="info"
                  btnText={parse(t("Next Topic"))}
                  className="btnNext"
                  hidden={currentPage != 13}
                  // onClick={() => {
                  //   setCurrentPage(currentPages[nextCount - 1])
                  //   setInstructionsInfo(instructions[nextCount - 1])
                  //   setNextBtnEnabled(false)
                  //   setNextCount(nextCount - 1)
                  // }}
                ></ButtonLink>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
      </div>
    </Layout>
  )
}

export default ExpGrowthStep4
